import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CustomerInfoCard } from 'pages/Checkout/CustomerInfoCard';
import { PromotionCodeCard } from 'pages/Checkout/PromotionCodeCard';
import { ConfirmCard } from 'pages/Checkout/ConfirmCard';
import { CollapseCard } from 'components/CollapseCard/CollapseCard';
import { Loader } from 'components/Loader/Loader';
import { getPrice, urls } from 'helpers';
import { Namespaces } from 'languages';
import { DONT_ASK, MANDATORY } from 'constants/checkoutConstants';

import Layout from 'styled/Layout';

import { BankIDCard } from './BankIDCard';
import { SignDocumentCard } from './SignDocumentCard';
import { AddonsCard } from './AddonsCard';
import {
  HeaderElements,
  CardTitle,
  CardHeaderErrorLabel,
  PriceDiscount,
  Header,
  Modal,
  ModalBody,
} from './styles/checkoutStyles';
import { useCheckout } from 'hooks/useCheckout';
import {
  BookingsBodyInfo,
  BookingsHeaderInfo,
  ModalCancelBody,
} from './HelperComponents';
import { GiftCard } from './GiftCard';

export const Checkout: FC = observer(() => {
  const history = useHistory();
  const {
    renderError,
    checkoutErrors,
    checkoutData,
    activePromotion,
    checkoutId,
    removeItemFromCheckout,
    userProxies,
    transactionLinkHash,
    phoneNumber: { phoneNumber, setPhoneNumber },
    customerMessage: { customerMessage, setCustomerMessage },
  } = useCheckout();
  const { t } = useTranslation(Namespaces.UI);
  const [isCheckoutItemsCardOpenByError, setIsCheckoutItemsCardOpenByError] = useState(
    false
  );
  const [isAddonsCardOpenByError, setIsAddonsInfoCardOpenByError] = useState(false);
  const [isCustomerInfoCardOpenByError, setIsCustomerInfoCardOpenByError] = useState(
    false
  );
  const [isDocumentSignCardOpenByError, setIsDocumentSignCardOpenByError] = useState(
    false
  );
  const [isBankIDCardOpenByError, setIsBankIDCardOpenByError] = useState(false);
  const [isGiftCardOpenByError, setIsGiftCardOpenByError] = useState(false);
  const [activeDeleteItemId, setActiveDeleteItemId] = useState<number | undefined>(
    undefined
  );

  renderError();

  const handleModalCancel = () => {
    if (activeDeleteItemId && checkoutData) {
      const lastItem = checkoutData?.itemCount === 1;
      const callback = lastItem
        ? history.push(urls.services(localStorage.profileId))
        : setActiveDeleteItemId(undefined);

      removeItemFromCheckout(checkoutId, activeDeleteItemId, () => callback);
    }
  };

  const handleModalKeep = () => {
    setActiveDeleteItemId(undefined);
  };

  const isSigningDocumentError = checkoutErrors?.documentSigningStatus;
  const showAddonsCard =
    checkoutData?.addonsMode !== DONT_ASK && (checkoutData?.addons?.length || 0) > 0;
  const showCustomerInfoCard =
    checkoutData?.customerMessageMode !== DONT_ASK ||
    checkoutData?.phoneNumberMode !== DONT_ASK;
  const showBankIDCard = checkoutData?.bankIDMode !== DONT_ASK;
  const showDocumentSigningCard = checkoutData?.documentSigningMode !== DONT_ASK;
  const showPromotionCard = checkoutData?.promotionCodeMode !== DONT_ASK;
  const showGiftCard = checkoutData?.giftMode !== DONT_ASK;

  return (
    <Layout>
      <Header>{t('checkout')}</Header>
      {checkoutData ? (
        <>
          <div id="checkoutItemsCardAnchor">
            <CollapseCard
              header={<BookingsHeaderInfo checkoutData={checkoutData} />}
              number={1}
              body={
                <BookingsBodyInfo
                  checkoutData={checkoutData}
                  userProxies={userProxies}
                  setActiveDeleteItemId={setActiveDeleteItemId}
                />
              }
              isOpen={true}
              isOpenByError={isCheckoutItemsCardOpenByError}
              setIsOpenByError={setIsCheckoutItemsCardOpenByError}
            />
          </div>

          <div id="addonsCardAnchor">
            {showAddonsCard ? (
              <CollapseCard
                header={
                  <HeaderElements>
                    <CardTitle>{t('addons')}</CardTitle>
                    {checkoutErrors?.addonsMandatory && (
                      <CardHeaderErrorLabel>
                        {checkoutErrors.addonsMandatory}
                      </CardHeaderErrorLabel>
                    )}
                  </HeaderElements>
                }
                number={2}
                body={
                  <AddonsCard
                    checkout={checkoutData}
                    transactionLinkHash={transactionLinkHash}
                  />
                }
                isOpen={checkoutData?.addonsMode === MANDATORY}
                isOpenByError={isAddonsCardOpenByError}
                setIsOpenByError={setIsAddonsInfoCardOpenByError}
              />
            ) : null}
          </div>

          <div id="documentBankIDAnchor" />
          {showBankIDCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('checkoutBankIDCardTitle')}</CardTitle>
                </HeaderElements>
              }
              number={+showAddonsCard + 2}
              body={<BankIDCard checkoutId={checkoutData?.id} />}
              isOpen={checkoutData?.bankIDMode === MANDATORY}
              isOpenByError={isBankIDCardOpenByError}
              setIsOpenByError={setIsBankIDCardOpenByError}
            />
          )}

          <div id="customerInfoCardAnchor" />
          {showCustomerInfoCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('contactInformation')}</CardTitle>
                </HeaderElements>
              }
              number={+showAddonsCard + +showBankIDCard + 2}
              body={
                <CustomerInfoCard
                  phoneNumberMode={checkoutData?.phoneNumberMode}
                  customerMessageMode={checkoutData?.customerMessageMode}
                  phoneNumber={phoneNumber}
                  checkout={checkoutData}
                  setPhoneNumber={setPhoneNumber}
                  customerMessage={customerMessage}
                  setCustomerMessage={setCustomerMessage}
                />
              }
              isOpen={
                checkoutData?.customerMessageMode === MANDATORY ||
                checkoutData?.phoneNumberMode === MANDATORY
                  ? true
                  : false
              }
              isOpenByError={isCustomerInfoCardOpenByError}
              setIsOpenByError={setIsCustomerInfoCardOpenByError}
            />
          )}

          <div id="documentSignCardAnchor" />
          {showDocumentSigningCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('checkoutSignDocumentTitle')}</CardTitle>
                </HeaderElements>
              }
              number={+showBankIDCard + +showCustomerInfoCard + +showAddonsCard + 2}
              body={<SignDocumentCard checkoutId={checkoutData?.id} />}
              isOpen={
                checkoutData?.documentSigningMode === MANDATORY || isSigningDocumentError
                  ? true
                  : false
              }
              isOpenByError={isDocumentSignCardOpenByError}
              setIsOpenByError={setIsDocumentSignCardOpenByError}
            />
          )}

          {showPromotionCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('promotionCode')}</CardTitle>
                  {checkoutData.promotionCodeDiscount ? (
                    <PriceDiscount>
                      {`- ${getPrice(
                        checkoutData.promotionCodeDiscount,
                        checkoutData.currency
                      )}`}
                    </PriceDiscount>
                  ) : (
                    <></>
                  )}
                </HeaderElements>
              }
              isOpen={
                checkoutData?.promotionCode ||
                activePromotion ||
                checkoutData?.promotionCodeMode === MANDATORY
                  ? true
                  : false
              }
              number={
                +showBankIDCard +
                +showCustomerInfoCard +
                +showDocumentSigningCard +
                +showAddonsCard +
                2
              }
              body={<PromotionCodeCard checkoutId={checkoutData?.id} />}
            />
          )}
          <div id="giftCardAnchor" />
          {showGiftCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('makeItAGift')}</CardTitle>
                </HeaderElements>
              }
              isOpen={!!checkoutData?.giftEmail || checkoutData?.giftMode === MANDATORY}
              number={
                +showBankIDCard +
                +showCustomerInfoCard +
                +showDocumentSigningCard +
                +showAddonsCard +
                +showPromotionCard +
                2
              }
              isOpenByError={isGiftCardOpenByError}
              setIsOpenByError={setIsGiftCardOpenByError}
              body={<GiftCard checkoutId={checkoutData?.id} />}
            />
          )}
          <CollapseCard
            header={
              <HeaderElements>
                <CardTitle>{t('confirm')}</CardTitle>
              </HeaderElements>
            }
            isOpen={true}
            number={
              +showBankIDCard +
              +showCustomerInfoCard +
              +showDocumentSigningCard +
              +showPromotionCard +
              +showAddonsCard +
              +showGiftCard +
              2
            }
            body={
              <ConfirmCard
                setIsCheckoutItemsCardOpenByError={setIsCheckoutItemsCardOpenByError}
                setIsAddonsCardOpenByError={setIsAddonsInfoCardOpenByError}
                setIsCustomerInfoCardOpenByError={setIsCustomerInfoCardOpenByError}
                setIsDocumentSignCardOpenByError={setIsDocumentSignCardOpenByError}
                setIsBankIDCardOpenByError={setIsBankIDCardOpenByError}
                setIsGiftCardOpenByError={setIsGiftCardOpenByError}
              />
            }
          />
        </>
      ) : (
        <Loader />
      )}
      {activeDeleteItemId && (
        <Modal>
          <ModalBody>
            <ModalCancelBody
              handleCancel={handleModalCancel}
              handleModalKeep={handleModalKeep}
            />
          </ModalBody>
        </Modal>
      )}
    </Layout>
  );
});
