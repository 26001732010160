import { useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { getPrice } from 'helpers';
import { getDurationTextFromMinutes, isLocalTimeZoneSameAs } from 'helpers/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Loader } from 'components/Loader/Loader';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { TimeButton } from 'components/TimeButton/TimeButton';

import { Namespaces } from 'languages';
import { CheckoutStore, AddCheckoutItemData } from 'store/CheckoutStore';
import { AppointmentSlotFixed } from 'store/ServiceStore';

import PriceIcon from 'assets/icons/price.svg';
import Clock from 'assets/icons/time.svg';

import AppointmentCardContainer from 'styled/AppointmentCardContainer';
import AppointmentCardCoverImage from 'styled/AppointmentCardCoverImage';
import { Flex } from 'styled/Flex';
import { Avatar } from 'components/Avatar';
import { CollapseText } from 'components/CollapseText/CollapseText';
import { CardDescription } from './styles';
import {
  CardButton,
  CardHeader,
  CardPriceData,
  CardTime,
  ServiceDuration,
  SlotsContainer,
  SlotsLayout,
  TimeZoneText,
} from './fixedCardStyles';
import { ExpandCollapse } from 'components/ExpandCollapse';

export const AppointmentCardFixedSlots = observer(
  (props: { appointment: AppointmentSlotFixed; defaultActive: boolean }) => {
    const {
      appointment: {
        avatarUrl,
        price,
        fromPrice,
        currency,
        durationMinutes,
        name,
        slots,
        description,
        coverImageUrl,
      },
      defaultActive,
    } = props;

    const { profileId } = useParams<{ profileId: string }>();
    const [loading, setLoading] = useState(false);
    const [activeTimeButtons, setActiveTimeButton] = useState<number[]>([]);
    const [isCollapsed, setCollapsed] = useState(defaultActive);
    const { addItemToCheckout } = CheckoutStore;
    const { t } = useTranslation(Namespaces.UI);
    const getRequestData = () => {
      return activeTimeButtons.reduce((acc: AddCheckoutItemData[] | [], position) => {
        const checkoutItemDataItems = acc;
        if (slots[position]?.checkoutItemData) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore-next-line
          checkoutItemDataItems.push(slots[position].checkoutItemData);
        }

        return checkoutItemDataItems;
      }, []);
    };

    let servicePrice = getPrice(fromPrice || price, currency);
    if (fromPrice) {
      servicePrice = `${t('from')} ${servicePrice}`;
    }

    let localTimeZoneDiffers = false;
    if (slots?.length > 0) {
      const firstSlot = slots[0];
      const lastSlot = slots[slots.length - 1];
      localTimeZoneDiffers =
        !isLocalTimeZoneSameAs(firstSlot.startTime) ||
        !isLocalTimeZoneSameAs(lastSlot.endTime);
    }

    return (
      <div data-testid="appointment-card-fixed-slots">
        <AppointmentCardContainer
          collapsed={isCollapsed}
          onClick={() => {
            setCollapsed(true);
          }}
          withPaddingTop={!coverImageUrl}
        >
          {coverImageUrl && isCollapsed ? (
            <AppointmentCardCoverImage img={coverImageUrl} />
          ) : null}
          <Flex justifyContent="space-between" style={{ padding: '0 25px' }}>
            <Flex alignItems="center">
              {avatarUrl && (
                <Avatar size={50} url={avatarUrl} style={{ marginRight: '22px' }} />
              )}
              <Flex flexDirection="column">
                <CardHeader>{name}</CardHeader>
                <CardDescription>
                  <CollapseText text={description} maxLines={2} />
                </CardDescription>
                <Flex alignItems="center">
                  {servicePrice && (
                    <>
                      <CustomIcon width="16px" height="16px" icon={PriceIcon} />
                      <CardPriceData>{servicePrice}</CardPriceData>
                    </>
                  )}
                  <CardTime>
                    <CustomIcon width="14px" height="14px" icon={Clock} />
                    <ServiceDuration>
                      {`${getDurationTextFromMinutes(durationMinutes)}`}
                    </ServiceDuration>
                  </CardTime>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {isCollapsed && (
            <SlotsContainer>
              {localTimeZoneDiffers && (
                <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>
              )}
              <SlotsLayout>
                {slots.map((data, i) => (
                  <TimeButton
                    key={i}
                    startTime={data.startTime}
                    endTime={data.endTime}
                    action={() => {
                      if (activeTimeButtons.includes(i)) {
                        setActiveTimeButton(activeTimeButtons.filter((el) => el !== i));
                      } else {
                        setActiveTimeButton([...activeTimeButtons, i]);
                      }
                    }}
                    active={activeTimeButtons.includes(i)}
                  />
                ))}
              </SlotsLayout>
              {activeTimeButtons.length > 0 && (
                <CardButton
                  disabled={!activeTimeButtons.length}
                  onClick={() => {
                    if (!loading) {
                      setLoading(true);
                      addItemToCheckout(profileId, getRequestData(), setLoading);
                      postCTAEventMessage(getAddBookingCTAEvent());
                    }
                  }}
                >
                  {loading ? <Loader isWhite small /> : t('addToCart')}
                </CardButton>
              )}
            </SlotsContainer>
          )}

          <ExpandCollapse isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        </AppointmentCardContainer>
      </div>
    );
  }
);
