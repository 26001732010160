import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Flex } from 'styled/Flex';
import { CourseDateType } from 'store/CourseStore';
import { Namespaces } from 'languages';
import { getTime, getYMD, getWeekdayName } from 'helpers/DateTimeUtils';
import { LoginStore } from 'store/LoginStore';
import { toJS } from 'mobx';
import { Loader } from 'components/Loader/Loader';
import {
  ScheduleBlock,
  ScheduleContent,
  ScheduleHeadBlock,
  ScheduleName,
  ScheduleLength,
  SelectAllButton,
  ScheduleDate,
  WeekdayName,
  ScheduleDurationTime,
  LoadMoreButton,
} from './occasionsStyles';
import { ScheduleButton } from './ScheduleButton';
import { Course } from 'store/ServiceStore';
import { DONT_ASK } from 'constants/checkoutConstants';

interface IProps {
  data: CourseDateType[];
  isBookPerOccasion: boolean;
  selectedButtons: number[];
  setSelectedButtons: Dispatch<SetStateAction<number[]>>;
  page: number;
  totalPages: number;
  totalRows: number;
  id: number | string;
  itemsToSelect: number[];
  allEntries: boolean;
  isCourseBookable: boolean;
  setAllEntries: (value: boolean) => void;
  fetchCourseOccasions: (
    serviceId: string | number,
    numberOfPage: number,
    setLoading: (value: boolean) => void
  ) => Promise<void>;
  addToCourseOccasionWaitingList: (
    { id, userProxiesIds }: { id: number | string; userProxiesIds?: number[] },
    setLoading: (element: number[]) => void
  ) => Promise<void>;
  setModal: Dispatch<SetStateAction<number | null>>;
  proxyUsersMode: Course['proxyUsersMode'];
}

export const CourseOccasions: FC<IProps> = observer(
  ({
    data,
    isBookPerOccasion,
    selectedButtons,
    setSelectedButtons,
    page,
    totalPages,
    totalRows,
    id,
    itemsToSelect,
    allEntries,
    isCourseBookable,
    setAllEntries,
    fetchCourseOccasions,
    addToCourseOccasionWaitingList,
    setModal,
    proxyUsersMode,
  }) => {
    const { user } = LoginStore;
    const userData = toJS(user);
    const [loading, setLoading] = useState<number[]>([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const { t } = useTranslation(Namespaces.UI);

    useEffect(() => {
      const { elementId } = localStorage;
      if (elementId && userData.id) {
        if (proxyUsersMode === DONT_ASK)
          addToCourseOccasionWaitingList({ id: elementId }, setLoading);
        else setModal(elementId);
        localStorage.removeItem('elementId');
      }
    }, [userData.id, addToCourseOccasionWaitingList, proxyUsersMode]);

    return (
      <Flex flexDirection="column">
        <ScheduleHeadBlock>
          <ScheduleName>{t('schedule')}</ScheduleName>
          <ScheduleLength>
            {totalRows === 1
              ? `(${totalRows} ${t('occasion')})`
              : `(${totalRows} ${t('occasions')})`}
          </ScheduleLength>
          {isBookPerOccasion && isCourseBookable && (
            <>
              {allEntries ? (
                <SelectAllButton
                  onClick={() => {
                    setSelectedButtons([]);
                    setAllEntries(false);
                  }}
                >
                  {t('unselectAll')}
                </SelectAllButton>
              ) : (
                <SelectAllButton
                  onClick={() => {
                    setSelectedButtons(itemsToSelect);
                    setAllEntries(true);
                  }}
                >
                  {t('selectAll')}
                </SelectAllButton>
              )}
            </>
          )}
        </ScheduleHeadBlock>
        {data.map((el) => {
          const isDisabled =
            el.isCallerAttending || (el.isFull && !el.isWaitingListEnabled);
          const isRed =
            el.isFull &&
            (el.isCallerOnWaitingList ||
              (el.proxyUserIdsOnWaitingList || [])?.length > 0);
          return (
            <ScheduleBlock key={el.id}>
              <ScheduleContent disabled={isDisabled} isRed={isRed}>
                <Flex flexDirection="column">
                  <ScheduleDate>{getYMD(el.startTime, true)}</ScheduleDate>
                  <WeekdayName>{t(getWeekdayName(el.startTime, true))}</WeekdayName>
                </Flex>
                <ScheduleDurationTime>
                  {`${getTime(el.startTime, true)} - ${getTime(el.endTime, true)}`}
                </ScheduleDurationTime>
                <ScheduleButton
                  element={el}
                  loading={loading}
                  setLoading={setLoading}
                  addToCourseOccasionWaitingList={addToCourseOccasionWaitingList}
                  isBookPerOccasion={isBookPerOccasion}
                  isCourseBookable={isCourseBookable}
                  itemsToSelect={itemsToSelect}
                  selectedButtons={selectedButtons}
                  setSelectedButtons={setSelectedButtons}
                  allEntries={allEntries}
                  setAllEntries={setAllEntries}
                  setModal={setModal}
                  proxyUsersMode={proxyUsersMode}
                />
              </ScheduleContent>
            </ScheduleBlock>
          );
        })}
        {page !== totalPages && (
          <LoadMoreButton
            onClick={() => {
              if (!loadingMore) {
                setLoadingMore(true);
                fetchCourseOccasions(id, page + 1, setLoadingMore);
              }
            }}
          >
            {loadingMore ? <Loader small /> : t('loadMore')}
          </LoadMoreButton>
        )}
      </Flex>
    );
  }
);
