import { devices } from 'constants/mediaConstants';
import styled, { DefaultTheme } from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const CardDescription = styled.div`
  padding: 0 25px;
  margin: 10px 0;
  padding-right: 40px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.middleDark};
  white-space: pre-wrap;
  @media ${devices.mobile} {
    font-size: 14px;
    padding-right: 20px;
  }
`;

export const CardPriceData = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  color: ${({ theme }) => theme.colors.darkBlack};
`;

export const CardDateContainer = styled.div`
  margin-bottom: 30px;
  padding: 0 25px;
`;

export const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 0px auto 0px;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  }
`;

export const ModalDateTitle = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 700;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

export const ModalBody = styled.div`
  position: relative;
  height: 80vh;
  width: 545px;
  padding: 65px 67px 40px 65px;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    width: 90%;
    padding: 30px;
    font-size: 12px;
  }
`;

export const ModalTimeButtonWrapper = styled.div`
  height: 54vh;
  overflow: scroll;
`;

export const IconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
  }
`;

export const ModalCardName = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #a6a6a6;
  margin: 20px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

export const TimeLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px;
`;

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  overflow: hidden;
  height: 35px;
`;

export const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props: { column: number }) => props.column}, 1fr);
  grid-gap: 10px;
  padding-bottom: 20px;
  overflow-x: scroll;
  @media (max-width: 430px) {
    grid-gap: 5px;
  }
`;

type MinMaxBlockProps = {
  theme: DefaultTheme;
  isError?: boolean;
};

export const MinMaxBlock = styled.div`
  border: ${(props: MinMaxBlockProps) =>
    props.isError ? '1px solid #fd2b55' : '1px solid #ffffff'};
  border-radius: 5px;
  padding: 2px 8px;
  margin: 6px 10px 0 0;
  box-sizing: border-box;
  color: ${(props: MinMaxBlockProps) =>
    props.isError ? props.theme.colors.whiteRed : props.theme.colors.middleDark};
  font-family: ${(props: MinMaxBlockProps) => props.theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
`;
