import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
`;

export const CardDescription = styled.div`
  padding: 0 25px;
  margin-top: 9px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  @media ${devices.mobile} {
    font-size: 15px;
  }
`;

export const CardPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 17px;
  padding: 0 25px;
`;

export const CardPriceData = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
`;

export const CardAddress = styled.div`
  padding: 0 25px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

export const CardButton = styled(PrimaryButton)`
  width: 214px;
  max-height: 56px;
  margin: 30px auto 0;
  padding: 17px 50px;
  font-size: 14px;

  @media ${devices.mobile} {
    width: 170px;
    max-height: 38px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;
