import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Service } from 'components/Service/Service';
import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';
import { ServicePanel } from 'components/ServicePanel/ServicePanel';
import { DiscountAlert } from 'components/DiscountAlert/DiscountAlert';
import { Namespaces } from 'languages';

import { ServiceListStore, ServiceType } from 'store/ServiceListStore';
import { HeaderStore } from 'store/HeaderStore';
import { LoginStore } from 'store/LoginStore';

import Layout from 'styled/Layout';

const ServiceListPage = styled(Layout)`
  margin: 20px 0;
`;

export const ServiceList = observer(() => {
  const { fetchServices, getServices, loading } = ServiceListStore;
  const { setBackButton, setProfileId, setTitle, isBackButton, title } = HeaderStore;
  const { profileId } = useParams<{ profileId: string }>();
  const { user } = LoginStore;
  const userData = toJS(user);
  const { priceReducers } = userData;
  const { t } = useTranslation(Namespaces.UI);

  const localProfileId = localStorage.getItem('profileId');
  const filterPriceReducers = priceReducers
    ? priceReducers.filter((el) => {
        return el.profile.id === Number(localProfileId);
      })
    : [];

  const searchQuery = useLocation().search;
  const categoryIds = new URLSearchParams(searchQuery).getAll('categoryId');
  const expandedCategoryIds = new URLSearchParams(searchQuery).getAll('expCategoryId');

  useEffect(() => {
    localStorage.profileId = profileId;
    setProfileId(profileId);
    fetchServices(profileId, categoryIds);
  }, []);

  useEffect(() => {
    setBackButton(false);
    setTitle('');
  }, [title, isBackButton]);

  const services = getServices();

  if (!services) {
    return <Loader />;
  }

  const renderService = (service: ServiceType) => {
    const {
      name,
      description,
      price,
      fromPrice,
      currency,
      id,
      durationMinutes,
      startTime,
      endTime,
      pricePerUnit,
      balance,
      type,
      priceReductions,
      spotsLeft,
    } = service;
    return (
      <Service
        id={id}
        profileId={profileId}
        name={name}
        description={description}
        price={price}
        fromPrice={fromPrice}
        currency={currency}
        durationMinutes={durationMinutes}
        key={id}
        startTime={startTime}
        endTime={endTime}
        pricePerUnit={pricePerUnit}
        balance={balance}
        priceReductions={priceReductions}
        type={type}
        spotsLeft={spotsLeft}
      />
    );
  };

  const categoryNames = Object.keys(services);

  const renderServices = (categoryName: string, categoryServices: ServiceType[]) => {
    if (categoryName.startsWith('emptyCategory')) {
      return categoryServices.map((service: any) => renderService(service));
    }

    let expandedByDefault = categoryNames.length === 1;
    if (!expandedByDefault && expandedCategoryIds.length > 0) {
      const serviceCategoryId = categoryServices[0]?.category?.id;
      expandedByDefault = expandedCategoryIds.some(
        (categoryId) => parseInt(categoryId, 10) === serviceCategoryId
      );
    }

    return (
      <ServicePanel
        header={categoryName}
        serviceCount={categoryServices.length}
        key={categoryName}
        isExpandedByDefault={expandedByDefault}
      >
        <>{categoryServices.map((service: ServiceType) => renderService(service))}</>
      </ServicePanel>
    );
  };

  return (
    <ServiceListPage data-testid="service-list">
      {categoryNames.length ? (
        <>
          {filterPriceReducers.length ? (
            <DiscountAlert priceReducers={filterPriceReducers} />
          ) : null}
          {categoryNames.map((name: string) => renderServices(name, services[name]))}
        </>
      ) : (
        <EmptyResult
          text={t('noServicesFound')}
          action={() => fetchServices(profileId, categoryIds)}
          loading={loading}
        />
      )}
    </ServiceListPage>
  );
});
