import { makeAutoObservable } from 'mobx';
import { callApi, postApi } from 'helpers';
import { ServiceStore } from './ServiceStore';
import { LoginStore } from './LoginStore';

export type CourseDateType = {
  endTime: string;
  isWaitingListEnabled: boolean;
  id: number;
  isCallerAttending: boolean;
  isCallerOnWaitingList: boolean;
  isFull: boolean;
  startTime: string;
  proxyUserIdsOnWaitingList?: number[];
};

export type CourseType = {
  data: CourseDateType[];
  page: number;
  perPage: number;
  totalPages: number;
  totalRows: number;
};

class Course {
  course: CourseType | undefined = undefined;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchCourse = async (serviceId: string | number) => {
    try {
      const res = await callApi(`v1/courses/${serviceId}/occasions`);
      const data = await res.json();

      this.setCourse(data);
    } catch (e) {
      console.error(e);
    }
  };

  fetchCourseOccasions = async (
    serviceId: string | number,
    numberOfPage: number,
    setLoading: (value: boolean) => void
  ) => {
    const res = await callApi(`v1/courses/${serviceId}/occasions?page=${numberOfPage}`);
    const { data, page, perPage, totalPages, totalRows } = await res.json();

    if (data) {
      const oldData = this.course ? this.course?.data : [];
      const newData = [...oldData, ...data];
      this.setCourse({ data: newData, page, perPage, totalPages, totalRows });
    }
    setLoading(false);
  };

  addToCourseWaitingList = async (
    { id, userProxyIds }: { id: number | string; userProxyIds?: number[] },
    setLoading?: (value: boolean) => void
  ) => {
    const { getUserStatus } = LoginStore;
    try {
      if (id) {
        const res = await postApi(`/v1/courses/${id}/waiting-list/join`, {
          userProxyIds,
        });
        const data = await res.json();
        ServiceStore.setService(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading?.(false);
      getUserStatus();
    }
  };

  addToCourseOccasionWaitingList = async (
    { id, userProxyIds }: { id: number | string; userProxyIds?: number[] },
    setLoading?: (value: number[]) => void
  ) => {
    const { getUserStatus } = LoginStore;
    try {
      if (id) {
        const res = await postApi(`/v1/course-occasions/${id}/waiting-list/join`, {
          userProxyIds,
        });
        const data = await res.json();
        if (this.course) {
          const { page, perPage, totalPages, totalRows } = this.course;
          const newData = this.course?.data.map((element) => {
            if (element.id === +id) {
              return data as CourseDateType;
            }
            return element;
          });
          this.setCourse({ data: newData, page, perPage, totalPages, totalRows });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading?.([]);
      getUserStatus();
    }
  };

  setCourse = (course: CourseType) => {
    this.course = course;
  };

  clearCourse = () => {
    this.course = undefined;
  };
}

export const CourseStore = new Course();
